<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室绩效分配方案</h2>
                <div class="hosrow">
                    <div>
                        <el-select v-model="searcdepartmentId" placeholder="请选择科室" clearable @change="seardepId(searcdepartmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width:200px;margin-right:10px;padding-right: 10px;">
                        <el-input v-model="searName" placeholder="方案名称" clearable @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
                    </div>
                    <div>
                        <el-button type="primary" @click="depaddKeshi">新增绩效分配方案</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="方案名称" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center" width="200px">
                        <template slot-scope="scope">
                            <div class="remakSty">{{scope.row.remark}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="指标状态" align="center" width="100">
                        <template slot-scope="scope">
                            <!-- 指标状态（0启用 1禁用） -->
                            <div v-if="scope.row.status=='0'">
                                <el-switch v-model="value" active-color="#13ce66" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>

                            <div v-else-if="scope.row.status=='1'">
                                <el-switch v-model="novalue" active-color="#13ce66" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" align="center" width="160">
                    </el-table-column>

                    <el-table-column label="操作" align="center" width="130">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="primary" @click="copyLlsh(scope.row)"><i class="el-icon-document-copy"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击新增人员信息弹出框 -->
        <el-dialog title="新增绩效分配方案" :visible.sync="AddOnedialogVisible" width="340px" :before-close="AddOnedialogVisibleClose">

            <div class="wriClas">
                <el-form ref="performRef" :model="perforModel" :rules="perRules" label-width="95px" class="newInp">
                    <el-form-item label="科室：" prop="departmentId">
                        <el-select v-model="perforModel.departmentId" :disabled='disabled' placeholder="请选择科室" clearable @change="depChange(perforModel.departmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="方案名称：" prop="name">
                        <el-input v-model="perforModel.name" placeholder="请填写方案名称"></el-input>
                    </el-form-item>

                    <el-form-item label="备注：" prop="reamrk">
                        <el-input v-model="perforModel.reamrk" maxlength="250" show-word-limit type="textarea" placeholder="请填写备注"></el-input>
                    </el-form-item>

                    <div style="text-align:center;" class="btnCen">
                        <el-button v-if="submitShow" style="margin-top: 12px;" type="primary" @click="oneNext">下一步</el-button>
                    </div>
                </el-form>
            </div>

        </el-dialog>
        <!-- 复制 -->
        <el-dialog title="复制绩效分配方案" :visible.sync="AddCopydialogVisible" width="350px" :before-close="AddCopydialogVisibleClose">
            <div class="wriClas">
                <el-form ref="performOneRef" :model="perforModel" :rules="perRules" label-width="107px" class="newInp">
                    <el-form-item label="科室：" prop="departmentId">
                        <el-select v-model="perforModel.departmentId" :disabled='disabled' placeholder="请选择科室" clearable @change="depChange(perforModel.departmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="原方案名称：" prop="name">
                        <el-input v-model="perforModel.name" :disabled='disabled' placeholder="请填写原方案名称"></el-input>
                    </el-form-item>

                    <el-form-item label="新方案名称：" prop="newname">
                        <el-input v-model="perforModel.newname" placeholder="请填写新方案名称"></el-input>
                    </el-form-item>

                    <el-form-item label="备注：">
                        <el-input v-model="perforModel.newreamrk"  maxlength="250" show-word-limit type="textarea" placeholder="请填写备注"></el-input>
                    </el-form-item>

                    <div style="text-align:center;" class="btnCen">
                        <el-button v-if="submitShow" style="margin-top: 12px;" type="primary" @click="oneCopyNext">提交</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" width="85%" top="1vh" :before-close="AdddialogVisibleClose" :close-on-click-modal="false">

            <el-form ref="performRef" :model="perforModel" :rules="perRules" label-width="166px">

                <div class='wriClas upWrit'>
                    <el-form-item label="科室：" prop="departmentId">
                        <el-select v-model="perforModel.departmentId" :disabled='disabled' placeholder="请选择科室" clearable @change="depChange(perforModel.departmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="方案名称：" prop="name">
                        <el-input v-model="perforModel.name" placeholder="请填写方案名称"></el-input>
                    </el-form-item>

                    <el-form-item label="备注：" prop="reamrk">
                        <el-input v-model="perforModel.reamrk"  maxlength="250" show-word-limit type="textarea" placeholder="请填写备注"></el-input>
                    </el-form-item>
                </div>

                <div v-if="oneShow">
                    <el-steps :active="active" finish-status="success" align-center class="depstep">
                        <el-step v-for="(item,index) of stepParams" :key="index" :title="item.title" @click.native="tabSwitchBtn(item)" />
                    </el-steps>

                    <div class="depDivBig">

                        <div class="depDivSmal"  v-for="(item,index) of perforModel.basData"  :key="index">

                            <div class="deptooDiv">

                                <el-popover placement="bottom" width="400" trigger="click" v-if="item.targetName=='基本指标'">
                                    <div>指标内涵：{{item.intension}}</div>
                                    <div>指标说明：{{item.explain}}</div>
                                    <div class="topName" slot="reference">{{item.name}}<i class="el-icon-question"></i></div>
                                </el-popover>

                                <div class="topName" v-else>{{item.name}}</div>
                            </div>
                            <div v-if="item.name=='医疗服务项目'||item.name=='病历病种'">

                                <el-table v-if="item.name=='医疗服务项目'" ref="multipleTable" max-height="400" center border :data="item.indicatorThirdLibraryList" tooltip-effect="dark" style="width: 100%">
                                    <el-table-column type="index" label="序号" width="55" align="center">
                                    </el-table-column>
                                    <el-table-column prop="name" label="指标名称" align="center">
                                    </el-table-column>
                                    <el-table-column prop="intension" label="指标内涵" align="center">
                                        <template slot-scope="scope">
                                            <el-tooltip class="item" effect="dark" placement="top">
                                                <div class="heiClas">{{scope.row.intension}}</div>
                                                <div style="width: 500px" slot="content">
                                                    <div>{{scope.row.intension}}</div>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="explain" label="指标说明" align="center">
                                        <template slot-scope="scope">
                                            <el-tooltip class="item" effect="dark" placement="top">
                                                <div class="heiClas">{{scope.row.explain}}</div>
                                                <div style="width: 500px" slot="content">
                                                    <div>{{scope.row.explain}}</div>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="characteristic" label="特性" align="center">
                                        <!-- 指标特性（0加分 1减分 2固定分值  3评分） -->
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.characteristic=='0'">加分</div>
                                            <div v-else-if="scope.row.characteristic=='1'">减分</div>
                                            <div v-else-if="scope.row.characteristic=='2'">固定分值</div>
                                            <div v-else-if="scope.row.characteristic=='3'">评分</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="unit" label="单位" align="center">
                                        <!-- 单位（0次  1人/次  2半天/次） -->
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.unit=='0'">次</div>
                                            <div v-else-if="scope.row.unit=='1'">人/次</div>
                                            <div v-else-if="scope.row.unit=='2'">半天/次</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="score" label="配置分值" align="center" width="220px">
                                        <template slot-scope="scope">
                                            <el-form-item class="bigClass" :prop="'basData.'+index+'.indicatorThirdLibraryList.'+scope.$index+'.score'" :rules="{required:true, message:'请输入配置分值', trigger:'blur'}">
                                                <el-input class="sorClas" v-model="scope.row.score" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>

                                    </el-form-item>

                                </el-table>

                                <el-table v-if="item.name=='病历病种'" ref="multipleTable" max-height="400" center border :data="item.indicatorThirdLibraryList" tooltip-effect="dark" style="width: 100%">
                                    <el-table-column type="index" label="序号" width="55" align="center">
                                    </el-table-column>
                                    <el-table-column prop="name" label="指标名称" align="center">
                                    </el-table-column>
                                    <el-table-column prop="intension" label="指标内涵" align="center">
                                        <template slot-scope="scope">
                                            <el-tooltip class="item" effect="dark" placement="top">
                                                <div class="heiClas">{{scope.row.intension}}</div>
                                                <div style="width: 500px" slot="content">
                                                    <div>{{scope.row.intension}}</div>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="explain" label="指标说明" align="center">
                                        <template slot-scope="scope">
                                            <el-tooltip class="item" effect="dark" placement="top">
                                                <div class="heiClas">{{scope.row.explain}}</div>
                                                <div style="width: 500px" slot="content">
                                                    <div>{{scope.row.explain}}</div>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="characteristic" label="特性" align="center">
                                        <!-- 指标特性（0加分 1减分 2固定分值  3评分） -->
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.characteristic=='0'">加分</div>
                                            <div v-else-if="scope.row.characteristic=='1'">减分</div>
                                            <div v-else-if="scope.row.characteristic=='2'">固定金额</div>
                                            <div v-else-if="scope.row.characteristic=='3'">评分</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="score" label="配置分值" align="center" width="220px">
                                        <template slot-scope="scope">
                                            <el-form-item class="bigClass" :prop="'basData.'+index+'.indicatorThirdLibraryList.'+scope.$index+'.score'" :rules="{required:true, message:'请输入配置分值', trigger:'blur'}">
                                                <el-input class="sorClas" v-model="scope.row.score" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>

                                    <!-- </el-form-item> -->

                                </el-table>
                            </div>
                            <div v-else class="otherClas">
                                <div v-for="(it,ind) of item.indicatorThirdLibraryList" :key="ind">
                                    <el-form-item v-if="it.characteristic=='3'" :label="it.name+'：'">
                                        <el-popover placement="bottom" width="400" trigger="click">
                                            <div>指标内涵：{{it.intension}}</div>
                                            <div>指标说明：{{it.explain}}</div>
                                            <div v-if="it.characteristic=='0'">特性：系数加分</div>
                                            <div v-else-if="it.characteristic=='1'">特性：系数减分</div>
                                            <div v-else-if="it.characteristic=='2'">特性：固定金额</div>
                                            <div v-else-if="it.characteristic=='3'">特性：评分</div>
                                            <div v-else-if="it.characteristic=='4'">特性：百分比减分</div>
                                            <div v-if="it.unit=='0'">单位：次</div>
                                            <div v-else-if="it.unit=='1'">单位：人/次</div>
                                            <div v-else-if="it.unit=='2'">单位：半天/次</div>
                                            <div v-else-if="it.unit=='3'">单位：天</div>
                                            <div>建议分值：{{it.suggestedScore}}</div>
                                            <div slot="reference"><i class="el-icon-question"></i></div>
                                        </el-popover>

                                        <el-form-item class="woScCalas" label='优' :prop="'basData.'+index+'.indicatorThirdLibraryList.' + ind + '.goodScore'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                            <el-input v-model="it.goodScore" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                        </el-form-item>

                                        <el-form-item class="woScCalas" label='一般' :prop="'basData.'+index+'.indicatorThirdLibraryList.' + ind + '.generalScore'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                            <el-input v-model="it.generalScore" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                        </el-form-item>

                                        <el-form-item class="woScCalas" label='差' :prop="'basData.'+index+'.indicatorThirdLibraryList.' + ind + '.poorScore'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                            <el-input v-model="it.poorScore" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                        </el-form-item>

                                    </el-form-item>

                                    <el-form-item v-else :label="it.name+'：'" :prop="'basData.'+index+'.indicatorThirdLibraryList.' + ind + '.score'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                        <el-popover placement="bottom" width="400" trigger="click" v-if="it.targetName!== '基本指标'">
                                            <div>指标内涵：{{it.intension}}</div>
                                            <div>指标说明：{{it.explain}}</div>
                                            <div v-if="it.characteristic=='0'">特性：系数加分</div>
                                            <div v-else-if="it.characteristic=='1'">特性：系数减分</div>
                                            <div v-else-if="it.characteristic=='2'">特性：固定金额</div>
                                            <div v-else-if="it.characteristic=='3'">特性：评分</div>
                                            <div v-else-if="it.characteristic=='4'">特性：百分比减分</div>
                                            <div v-if="it.unit=='0'">单位：次</div>
                                            <div v-else-if="it.unit=='1'">单位：人/次</div>
                                            <div v-else-if="it.unit=='2'">单位：半天/次</div>
                                            <div v-else-if="it.unit=='3'">单位：天</div>
                                            <div>建议分值：{{it.suggestedScore}}</div>
                                            <div slot="reference"><i class="el-icon-question"></i></div>
                                        </el-popover>
                                        <div style="display:flex;">
                                            <el-input type="number" @input="change($event)" v-model="it.score" :placeholder="'请输入配置分值'"></el-input>
                                            <span v-if="it.characteristic=='4'" style="margin-left:5px;">%</span>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="text-align:center;" class="btnCen">
                    <el-button type="primary" style="margin-top: 12px;" @click="prestep">上一步</el-button>
                    <el-button v-if="submitShow" style="margin-top: 12px;" type="primary" @click="next">下一步</el-button>

                    <div v-if='subtoShow'>
                        <el-button style="margin-top: 12px;" v-if="subextClick" type="primary" :loading="subextClickKing" @click="nextSub">提交</el-button>
                         <el-button style="margin-top: 12px;" v-else type="info" :loading="subextClickKing"></el-button>
                    </div>

                    <div v-if='editShow'>
                        <el-button style="margin-top: 12px;" v-if="addextClick" type="primary" :loading="addextClickKing" @click="allocUpdate">修改</el-button>
                         <el-button style="margin-top: 12px;" v-else type="info" :loading="addextClickKing"></el-button>
                    </div>

                    <el-button type="primary" style="margin-top: 12px;margin-left:10px" @click="AdddialogVisibleClose">关闭</el-button>

                </div>

            </el-form>

        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        return {
            subextClick: true,
            subextClickKing: false,
            addextClick: true,
            addextClickKing: false,
            AddOnedialogVisible: false,
            AddCopydialogVisible: false,
            subtoShow: false,
            disabled: false,
            editShow: false,
            submitShow: true,
            oneShow: false,
            stepParams: [
                { title: "基本指标", index: 0, id: "0", workType: "" },
                {
                    title: "工作量指标-医疗服务项目",
                    index: 1,
                    id: "1",
                    workType: "1",
                },
                {
                    title: "工作量指标-病历病种",
                    index: 2,
                    id: "1",
                    workType: "0",
                },
                {
                    title: "工作量指标-业务量",
                    index: 3,
                    id: "1",
                    workType: "2",
                },
                { title: "专项奖励指标", index: 4, id: "2", workType: "" },
                {
                    title: "单项补助及奖惩指标",
                    index: 5,
                    id: "3",
                    workType: "",
                },
                { title: "个人考勤指标", index: 6, id: "4", workType: "" },
                { title: "附加职责", index: 7, id: "5", workType: "" },
            ],
            perforModel: {
                departmentId: "", //科室
                reamrk: "",
                name: "",
                newname: "",
                newreamrk: "",
                basType: [],
                basData: [],
                workmesep: [], //工作量-医疗服务项目
                basicsep: [], //基本指标
                merecsep: [], //工作量-病历病种
                busvolsep: [], //工作量-业务量
                sperainsep: [], //专项奖励指标
                insurepusep: [], //单项补助及奖惩指标
                addressep: [], //附加职责
                personsep: [], //个人考勤指标
            },
            depoptions: [],
            taroptions: [],
            perRules: {
                name: [
                    {
                        required: true,
                        message: "请填写方案名称",
                        trigger: "blur",
                    },
                ],
                newname: [
                    {
                        required: true,
                        message: "请填写新方案名称",
                        trigger: "blur",
                    },
                ],
                departmentId: [
                    {
                        required: true,
                        message: "请选择科室",
                        trigger: "change",
                    },
                ],
            },
            active: -1,
            listTit: "专项奖励指标管理",
            value: true,
            novalue: false,
            diaBtn: "新增",
            diaTit: "新增绩效分配方案",
            // 点击添加科室弹出框
            AdddialogVisible: false,
            AddDateModel: {},
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            wardId: "", //病区id
            perDetailList: [],
            schemeid: "",
            searcdepartmentId: "",
            searName: "",
            copyShoe: false,
        };
    },
    created() {
        this.rewardList();
        this.findDepartment();
    },
    methods: {
        seardepId(val) {
            this.searcdepartmentId = val;
            this.pageNum = 1;
            this.rewardList();
        },
        searchName() {
            this.pageNum = 1;
            this.rewardList();
        },
        change(e) {
            this.$forceUpdate();
        },
        depChange(val) {
            this.perforModel.departmentId = val;
            // console.log(val);
        },
        async findDepartment() {
            let data = {
                hospitalId: window.sessionStorage.getItem("hospitalId"), //登录后返回的 医院id
                nameLike: "", //查询用的 科室名称
                orderByColumn: "createDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.findDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.depoptions = res.data;
            }
        },
        ganhandleChange(val) {
            console.log(val);
        },
        prohandleChange(val) {
            console.log(val);
        },

        prestep() {
            this.submitShow = true;
            // console.log(this.active);
            if (this.active == 0) {
                this.active = 0;
                this.oneShow = true;
                this.$message({
                    message: "当前已是第一步",
                    type: "error",
                });
            } else {
                this.active--;
                if (this.diaTit == "修改绩效分配方案") {
                    this.echoalltype(this.wardId);
                } else {
                    this.findByType();
                    this.oneShow = true;
                    if (this.active >= 7) {
                        this.subtoShow = true;
                    } else {
                        this.subtoShow = false;
                    }
                }
            }
        },
        oneNext() {
            this.$refs.performRef.validate(async (valid) => {
                if (valid) {
                    this.AdddialogVisible = true;
                    this.next();
                }
            });
        },
        oneCopyNext() {
            this.$refs.performOneRef.validate(async (valid) => {
                if (valid) {
                    let data = _qs.stringify({
                        id: this.wardId, //旧方案id
                        name: this.perforModel.newname, //新方案名称
                        remark: this.perforModel.newreamrk, //新方案备注
                    });
                    let { data: res } = await this.$axios.copyScheme(data);
                    // console.log(res);
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.AddCopydialogVisibleClose();
                        this.rewardList();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                    // this.next();
                }
            });
        },
        next() {
            this.AddOnedialogVisible = false;
            // console.log(this.active);
            this.$refs.performRef.validate(async (valid) => {
                if (valid) {
                    if (this.active++ > 7) this.active = -1;

                    if (this.active >= 7) {
                        this.submitShow = false;
                        if (this.diaTit == "修改绩效分配方案") {
                            this.editShow = true;
                            this.subtoShow = false;
                        } else {
                            this.subtoShow = true;
                        }
                    } else {
                        this.submitShow = true;
                        this.subtoShow = false;
                    }
                    if (this.active >= 0) {
                        this.disabled = true;
                    }

                    if (this.diaTit == "修改绩效分配方案") {
                        setTimeout(() => {
                            this.echoalltype(this.wardId);
                        }, 0);
                    } else {
                        this.findByType();
                        this.oneShow = true;
                    }

                    this.formData();
                }
            });
        },
        formData() {
            for (let i = 0; i < this.stepParams.length; i++) {
                if (this.active == this.stepParams[i].index) {
                    if (this.stepParams[i].title == "个人考勤指标") {
                        let arrsep = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[m]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.insurepusep = arrsep;
                    }
                    if (this.stepParams[i].title == "单项补助及奖惩指标") {
                        let arrsep = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[m]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.sperainsep = arrsep;
                    }
                    if (this.stepParams[i].title == "专项奖励指标") {
                        let arrsep = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[m]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.busvolsep = arrsep;
                    }
                    if (this.stepParams[i].title == "工作量指标-医疗服务项目") {
                        let arrsep = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[m]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.basicsep = arrsep;
                    }
                    if (this.stepParams[i].title == "工作量指标-病历病种") {
                        let arrsep = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[m]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.workmesep = arrsep;
                    }
                    if (this.stepParams[i].title == "工作量指标-业务量") {
                        let arrsep = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[m]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.merecsep = arrsep;
                    }
                    if (this.stepParams[i].title == "附加职责") {
                        let arrsep = [];
                        for (
                            let i = 0;
                            i < this.perforModel.basData.length;
                            i++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[i]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.personsep = arrsep;
                    }
                }
            }
        },
        nextSub() {
            this.subextClick = false;
            this.subextClickKing = true;
            for (let i = 0; i < this.stepParams.length; i++) {
                if (this.active == this.stepParams[i].index) {
                    if (this.stepParams[i].title == "附加职责") {
                        let arrsep = [];
                        for (
                            let i = 0;
                            i < this.perforModel.basData.length;
                            i++
                        ) {
                            arrsep.push(
                                ...this.perforModel.basData[i]
                                    .indicatorThirdLibraryList
                            );
                        }
                        this.perforModel.addressep = arrsep;
                    }
                }
            }
            this.scheAdd();
        },
        // 修改
        allocUpdate() {
            this.$refs.performRef.validate(async (valid) => {
                if (valid) {
                    this.addextClick = false;
                    this.addextClickKing = true;
                    // console.log(this.perforModel);
                    for (let i = 0; i < this.stepParams.length; i++) {
                        if (this.active == this.stepParams[i].index) {
                            if (this.stepParams[i].title == "个人考勤指标") {
                                let arrsep = [];
                                for (
                                    let m = 0;
                                    m < this.perforModel.basData.length;
                                    m++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[m]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.personsep = arrsep;
                            }
                            if (
                                this.stepParams[i].title == "单项补助及奖惩指标"
                            ) {
                                let arrsep = [];
                                for (
                                    let m = 0;
                                    m < this.perforModel.basData.length;
                                    m++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[m]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.insurepusep = arrsep;
                            }
                            if (this.stepParams[i].title == "专项奖励指标") {
                                let arrsep = [];
                                for (
                                    let m = 0;
                                    m < this.perforModel.basData.length;
                                    m++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[m]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.sperainsep = arrsep;
                            }
                            if (
                                this.stepParams[i].title ==
                                "工作量指标-医疗服务项目"
                            ) {
                                let arrsep = [];
                                for (
                                    let m = 0;
                                    m < this.perforModel.basData.length;
                                    m++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[m]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.workmesep = arrsep;
                            }
                            if (
                                this.stepParams[i].title ==
                                "工作量指标-病历病种"
                            ) {
                                let arrsep = [];
                                for (
                                    let m = 0;
                                    m < this.perforModel.basData.length;
                                    m++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[m]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.merecsep = arrsep;
                            }
                            if (
                                this.stepParams[i].title == "工作量指标-业务量"
                            ) {
                                let arrsep = [];
                                for (
                                    let m = 0;
                                    m < this.perforModel.basData.length;
                                    m++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[m]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.busvolsep = arrsep;
                            }
                            if (this.stepParams[i].title == "附加职责") {
                                let arrsep = [];
                                for (
                                    let i = 0;
                                    i < this.perforModel.basData.length;
                                    i++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[i]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.addressep = arrsep;
                            }
                            if (this.stepParams[i].title == "基本指标") {
                                let arrsep = [];
                                for (
                                    let i = 0;
                                    i < this.perforModel.basData.length;
                                    i++
                                ) {
                                    arrsep.push(
                                        ...this.perforModel.basData[i]
                                            .indicatorThirdLibraryList
                                    );
                                }
                                this.perforModel.basicsep = arrsep;
                            }
                        }
                    }
                    let array = [],
                        arrResul = [];
                    array.push(
                        ...this.perforModel.addressep,
                        ...this.perforModel.workmesep,
                        ...this.perforModel.basicsep,
                        ...this.perforModel.merecsep,
                        ...this.perforModel.busvolsep,
                        ...this.perforModel.sperainsep,
                        ...this.perforModel.insurepusep,
                        ...this.perforModel.personsep
                    );
                    for (let i = 0; i < array.length; i++) {
                        let thirdLibraryId;
                        if (array[i].thirdLibraryId) {
                            thirdLibraryId = array[i].thirdLibraryId;
                        } else {
                            thirdLibraryId = array[i].id;
                        }
                        arrResul.push({
                            thirdLibraryId: thirdLibraryId,
                            ratingDimension: array[i].ratingDimension,
                            score: array[i].score,
                            poorScore: array[i].poorScore, //评分差分值
                            generalScore: array[i].generalScore, //评分一般分值
                            goodScore: array[i].goodScore, //评分优秀分值
                        });
                    }
                    this.perDetailList = arrResul;
                    // console.log(this.perDetailList);
                    this.subUpd();
                }
            });
        },
        async subUpd() {
            let data = {
                id: this.schemeid, //方案id
                departmentId: this.perforModel.departmentId,
                name: this.perforModel.name,
                remark: this.perforModel.reamrk,
                performanceAllocationSchemeDetailList: this.perDetailList,
            };
            let { data: res } = await this.$axios.allocUpdate(data);
            // console.log(res);
            this.addextClick = true;
            this.addextClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.rewardList();
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.AdddialogVisibleClose();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async scheAdd() {
            let array = [],
                arrResul = [];
            array.push(
                ...this.perforModel.addressep,
                ...this.perforModel.workmesep,
                ...this.perforModel.basicsep,
                ...this.perforModel.merecsep,
                ...this.perforModel.busvolsep,
                ...this.perforModel.sperainsep,
                ...this.perforModel.insurepusep,
                ...this.perforModel.personsep
            );
            for (let i = 0; i < array.length; i++) {
                arrResul.push({
                    thirdLibraryId: array[i].id,
                    ratingDimension: array[i].ratingDimension,
                    score: array[i].score,
                    poorScore: array[i].poorScore, //评分差分值
                    generalScore: array[i].generalScore, //评分一般分值
                    goodScore: array[i].goodScore, //评分优秀分值
                });
            }
            this.perDetailList = arrResul;
            // console.log(arrResul);
            let data = {
                departmentId: this.perforModel.departmentId,
                name: this.perforModel.name,
                remark: this.perforModel.reamrk,
                performanceAllocationSchemeDetailList: this.perDetailList,
            };
            let { data: res } = await this.$axios.scheAdd(data);
            // console.log(res);
            this.subextClick = true;
            this.subextClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.rewardList();
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.AdddialogVisibleClose();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async findByType() {
            let data = _qs.stringify({
                departmentId: this.perforModel.departmentId, //所属科室id
            });
            let { data: res } = await this.$axios.findByType(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                let array = [];

                // console.log(this.perforModel.basData);

                for (let i = 0; i < this.stepParams.length; i++) {
                    if (this.active == this.stepParams[i].index) {
                        if (this.stepParams[i].title == "基本指标") {
                            for (
                                let o = 0;
                                o < res.data.basicLibraryList.length;
                                o++
                            ) {
                                res.data.basicLibraryList[o].targetName =
                                    "基本指标";
                                for (
                                    let m = 0;
                                    m <
                                    res.data.basicLibraryList[o]
                                        .indicatorThirdLibraryList.length;
                                    m++
                                ) {
                                    res.data.basicLibraryList[
                                        o
                                    ].indicatorThirdLibraryList[m].targetName =
                                        "基本指标";
                                }
                            }
                            array.push(...res.data.basicLibraryList);
                        } else if (this.stepParams[i].title == "个人考勤指标") {
                            array.push(
                                ...res.data.personalAttendanceLibraryList
                            );
                        } else if (
                            this.stepParams[i].title == "单项补助及奖惩指标"
                        ) {
                            for (
                                let o = 0;
                                o <
                                res.data.individualSubsidyLibraryList.length;
                                o++
                            ) {
                                if (
                                    res.data.individualSubsidyLibraryList[o]
                                        .name == "工作评分"
                                ) {
                                    for (
                                        let p = 0;
                                        p <
                                        res.data.individualSubsidyLibraryList[o]
                                            .indicatorThirdLibraryList.length;
                                        p++
                                    ) {
                                        res.data.individualSubsidyLibraryList[
                                            o
                                        ].indicatorThirdLibraryList[
                                            p
                                        ].ratingDimension = "2";
                                    }
                                }
                            }
                            array.push(
                                ...res.data.individualSubsidyLibraryList
                            );
                        } else if (this.stepParams[i].title == "附加职责") {
                            array.push(
                                ...res.data.addResponsibilitiesLibraryList
                            );
                        } else if (this.stepParams[i].title == "专项奖励指标") {
                            array.push(...res.data.specialRewardsLibraryList);
                        } else if (
                            this.stepParams[i].title ==
                            "工作量指标-医疗服务项目"
                        ) {
                            for (
                                let i = 0;
                                i < res.data.workloadLibraryList.length;
                                i++
                            ) {
                                if (
                                    res.data.workloadLibraryList[i].name ==
                                    "医疗服务项目"
                                ) {
                                    array.push(res.data.workloadLibraryList[i]);
                                }
                            }
                        } else if (
                            this.stepParams[i].title == "工作量指标-病历病种"
                        ) {
                            for (
                                let i = 0;
                                i < res.data.workloadLibraryList.length;
                                i++
                            ) {
                                if (
                                    res.data.workloadLibraryList[i].name ==
                                    "病历病种"
                                ) {
                                    array.push(res.data.workloadLibraryList[i]);
                                }
                            }
                        } else if (
                            this.stepParams[i].title == "工作量指标-业务量"
                        ) {
                            for (
                                let i = 0;
                                i < res.data.workloadLibraryList.length;
                                i++
                            ) {
                                if (
                                    res.data.workloadLibraryList[i].name ==
                                    "业务量"
                                ) {
                                    array.push(res.data.workloadLibraryList[i]);
                                }
                            }
                        }
                    }
                }
                this.perforModel.basData = array;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async perfindDetail(val) {
            if (val !== "-1") {
                for (let i = 0; i < this.stepParams.length; i++) {
                    if (this.active == this.stepParams[i].index) {
                        // console.log(this.stepParams[i].title);
                        let data = {
                            departmentId: this.perforModel.departmentId,
                            indicatorLibraryType: this.stepParams[i].id,
                            workloadIndicatorType: this.stepParams[i].workType, // 工作量指标类型（0病例病种赋分   1医疗服务项目  2业务量）  该项参数在指标类型为工作量类型时为必传
                        };
                        let { data: res } = await this.$axios.perfindDetail(
                            data
                        );
                        // console.log(res);
                        if (res.code == 401) {
                            this.$router.push("/login");
                        } else if (res.code == 200) {
                            // console.log(res.data);
                            for (let n = 0; n < res.data.length; n++) {
                                for (
                                    let j = 0;
                                    j <
                                    res.data[n].indicatorThirdLibraryList
                                        .length;
                                    j++
                                ) {
                                    res.data[n].indicatorThirdLibraryList[
                                        j
                                    ].score = "";
                                }
                                // console.log(res.data);
                                if (
                                    this.stepParams[i].title ==
                                    "工作量指标-医疗服务项目"
                                ) {
                                    if (res.data[n].name == "医疗服务项目") {
                                        let arr = [];
                                        arr.push(res.data[n]);
                                        this.perforModel.basData = arr;
                                    }
                                } else if (
                                    this.stepParams[i].title ==
                                    "工作量指标-病历病种"
                                ) {
                                    if (res.data[n].name == "病历病种") {
                                        let arr = [];
                                        arr.push(res.data[n]);
                                        this.perforModel.basData = arr;
                                    }
                                } else if (
                                    this.stepParams[i].title ==
                                    "工作量指标-业务量"
                                ) {
                                    if (res.data[n].name == "业务量") {
                                        let arr = [];
                                        arr.push(res.data[n]);
                                        this.perforModel.basData = arr;
                                    }
                                } else {
                                    this.perforModel.basData = res.data;
                                }
                            }
                        }
                    }
                }
            }
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.rewardList();
        },
        // 点击添加科室
        depaddKeshi() {
            // this.AdddialogVisible = true;
            this.AddOnedialogVisible = true;
        },
        AddOnedialogVisibleClose() {
            this.copyShoe = false;
            this.disabled = false;
            if (this.$refs.performOneRef) {
                this.$refs.performOneRef.resetFields();
            }
            if (this.$refs.performRef) {
                this.$refs.performRef.resetFields();
            }
            this.AddOnedialogVisible = false;
            this.perforModel = {
                departmentId: "", //科室
                reamrk: "",
                newname: "",
                newreamrk: "",
                name: "",
                basData: [],
            };
        },
        AddCopydialogVisibleClose() {
            this.copyShoe = false;
            this.disabled = false;
            if (this.$refs.performRef) {
                this.$refs.performRef.resetFields();
            }
            if (this.$refs.performOneRef) {
                this.$refs.performOneRef.resetFields();
            }
            this.AddCopydialogVisible = false;
            this.perforModel = {
                departmentId: "", //科室
                reamrk: "",
                newname: "",
                newreamrk: "",
                name: "",
                basData: [],
            };
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.subtoShow = false;
            this.disabled = false;
            this.editShow = false;
            this.AdddialogVisible = false;
            this.diaTit = "新增绩效分配方案";
            this.diaBtn = "新增";
            this.submitShow = true;
            this.oneShow = false;
            this.active = -1;
            this.perDetailList = [];
            this.perforModel = {
                departmentId: "", //科室
                reamrk: "",
                name: "",
                newname: "",
                basData: [],
            };
        },
        // 点击赋值按钮
        async copyLlsh(row) {
            // console.log(row);
            this.AddCopydialogVisible = true;
            this.wardId = row.id;
            this.perforModel.name = row.name;
            this.perforModel.departmentId = row.departmentId;
            this.copyShoe = true;
            this.diaTit = "复制绩效分配方案";
            this.disabled = true;
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            // console.log(row);
            this.wardId = row.id;
            this.diaBtn = "修改";
            this.diaTit = "修改绩效分配方案";
            this.AdddialogVisible = true;
            this.AddDateModel = row;
            this.echoalltype(row.id);
            this.echoalltypeTwo(row.id);
            this.active = 0;
            this.oneShow = true;
            this.editShow = true;
            this.disabled = true;
        },
        // 点击stemp文字
        tabSwitchBtn(val) {
            if (
                this.diaTit == "修改绩效分配方案" ||
                this.diaTit == "复制绩效分配方案"
            ) {
                this.$refs.performRef.validate(async (valid) => {
                    if (valid) {
                        this.active = val.index;
                        this.echoalltype(this.wardId);
                        if (val.index >= 7) {
                            this.submitShow = false;
                            this.subtoShow = false;
                        } else {
                            this.submitShow = true;
                        }
                    }
                });
            } else {
                this.$message({
                    message: "请按照步骤进行填写",
                    type: "error",
                });
            }
        },
        async echoalltypeTwo(val) {
            let data = _qs.stringify({
                id: val,
            });
            let { data: res } = await this.$axios.echoalltype(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.perforModel.departmentId =
                    res.data.performanceAllocationScheme.departmentId;
                this.perforModel.name =
                    res.data.performanceAllocationScheme.name;
                this.perforModel.reamrk =
                    res.data.performanceAllocationScheme.remark;
                this.schemeid = res.data.performanceAllocationScheme.id;
            }
        },
        async echoalltype(val) {
            let data = _qs.stringify({
                id: val,
            });
            let { data: res } = await this.$axios.echoalltype(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                let workArr = [],
                    busArr = [],
                    merArr = [];
                for (let k = 0; k < res.data.workloadLibraryList.length; k++) {
                    if (
                        res.data.workloadLibraryList[k].name == "医疗服务项目"
                    ) {
                        for (
                            let l = 0;
                            l <
                            res.data.workloadLibraryList[k]
                                .performanceAllocationSchemeDetailList.length;
                            l++
                        ) {
                            workArr.push(
                                res.data.workloadLibraryList[k]
                                    .performanceAllocationSchemeDetailList[l]
                            );
                        }
                    }

                    if (res.data.workloadLibraryList[k].name == "业务量") {
                        for (
                            let l = 0;
                            l <
                            res.data.workloadLibraryList[k]
                                .performanceAllocationSchemeDetailList.length;
                            l++
                        ) {
                            busArr.push(
                                res.data.workloadLibraryList[k]
                                    .performanceAllocationSchemeDetailList[l]
                            );
                        }
                    }

                    if (res.data.workloadLibraryList[k].name == "病历病种") {
                        for (
                            let l = 0;
                            l <
                            res.data.workloadLibraryList[k]
                                .performanceAllocationSchemeDetailList.length;
                            l++
                        ) {
                            merArr.push(
                                res.data.workloadLibraryList[k]
                                    .performanceAllocationSchemeDetailList[l]
                            );
                        }
                    }
                }
                this.perforModel.workmesep = workArr;
                this.perforModel.busvolsep = busArr;
                this.perforModel.merecsep = merArr;

                let speArr = [];
                for (
                    let k = 0;
                    k < res.data.specialRewardsLibraryList.length;
                    k++
                ) {
                    for (
                        let l = 0;
                        l <
                        res.data.specialRewardsLibraryList[k]
                            .performanceAllocationSchemeDetailList.length;
                        l++
                    ) {
                        speArr.push(
                            res.data.specialRewardsLibraryList[k]
                                .performanceAllocationSchemeDetailList[l]
                        );
                    }
                }
                this.perforModel.sperainsep = speArr;

                let indArr = [];
                for (
                    let k = 0;
                    k < res.data.individualSubsidyLibraryList.length;
                    k++
                ) {
                    for (
                        let l = 0;
                        l <
                        res.data.individualSubsidyLibraryList[k]
                            .performanceAllocationSchemeDetailList.length;
                        l++
                    ) {
                        indArr.push(
                            res.data.individualSubsidyLibraryList[k]
                                .performanceAllocationSchemeDetailList[l]
                        );
                    }
                }
                this.perforModel.insurepusep = indArr;

                let addArr = [];
                for (
                    let k = 0;
                    k < res.data.addResponsibilitiesLibraryList.length;
                    k++
                ) {
                    for (
                        let l = 0;
                        l <
                        res.data.addResponsibilitiesLibraryList[k]
                            .performanceAllocationSchemeDetailList.length;
                        l++
                    ) {
                        addArr.push(
                            res.data.addResponsibilitiesLibraryList[k]
                                .performanceAllocationSchemeDetailList[l]
                        );
                    }
                }
                this.perforModel.addressep = addArr;

                let basArr = [];
                for (let k = 0; k < res.data.basicLibraryList.length; k++) {
                    for (
                        let l = 0;
                        l <
                        res.data.basicLibraryList[k]
                            .performanceAllocationSchemeDetailList.length;
                        l++
                    ) {
                        basArr.push(
                            res.data.basicLibraryList[k]
                                .performanceAllocationSchemeDetailList[l]
                        );
                    }
                }
                this.perforModel.basicsep = basArr;

                let perArr = [];
                for (
                    let k = 0;
                    k < res.data.personalAttendanceLibraryList.length;
                    k++
                ) {
                    for (
                        let l = 0;
                        l <
                        res.data.personalAttendanceLibraryList[k]
                            .performanceAllocationSchemeDetailList.length;
                        l++
                    ) {
                        perArr.push(
                            res.data.personalAttendanceLibraryList[k]
                                .performanceAllocationSchemeDetailList[l]
                        );
                    }
                }
                this.perforModel.personsep = perArr;

                // console.log(this.perforModel);

                let array = [];
                for (let j = 0; j < this.stepParams.length; j++) {
                    if (this.active == this.stepParams[j].index) {
                        if (this.stepParams[j].title == "个人考勤指标") {
                            for (
                                let i = 0;
                                i <
                                res.data.personalAttendanceLibraryList.length;
                                i++
                            ) {
                                array.push({
                                    name: res.data
                                        .personalAttendanceLibraryList[i].name,
                                    id: res.data.personalAttendanceLibraryList[
                                        i
                                    ].id,
                                    explain:
                                        res.data.personalAttendanceLibraryList[
                                            i
                                        ].explain,
                                    intension:
                                        res.data.personalAttendanceLibraryList[
                                            i
                                        ].intension,
                                    indicatorThirdLibraryList: [],
                                });
                                for (
                                    let m = 0;
                                    m <
                                    res.data.personalAttendanceLibraryList[i]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    array.forEach((item) => {
                                        if (
                                            item.name ==
                                            res.data
                                                .personalAttendanceLibraryList[
                                                i
                                            ].name
                                        ) {
                                            item.indicatorThirdLibraryList.push(
                                                {
                                                    name: res.data
                                                        .personalAttendanceLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .name,
                                                    ratingDimension:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].ratingDimension,
                                                    score: res.data
                                                        .personalAttendanceLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].score,
                                                    poorScore:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].poorScore,
                                                    generalScore:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].generalScore,
                                                    goodScore:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].goodScore,
                                                    id: res.data
                                                        .personalAttendanceLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].thirdLibraryId,
                                                    intension:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .intension,
                                                    explain:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .explain,
                                                    suggestedScore:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .suggestedScore,
                                                    characteristic:
                                                        res.data
                                                            .personalAttendanceLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .characteristic,
                                                    unit: res.data
                                                        .personalAttendanceLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .unit,
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        } else if (
                            this.stepParams[j].title == "单项补助及奖惩指标"
                        ) {
                            for (
                                let i = 0;
                                i <
                                res.data.individualSubsidyLibraryList.length;
                                i++
                            ) {
                                array.push({
                                    name: res.data.individualSubsidyLibraryList[
                                        i
                                    ].name,
                                    id: res.data.individualSubsidyLibraryList[i]
                                        .id,
                                    explain:
                                        res.data.individualSubsidyLibraryList[i]
                                            .explain,
                                    intension:
                                        res.data.individualSubsidyLibraryList[i]
                                            .intension,
                                    indicatorThirdLibraryList: [],
                                });
                                for (
                                    let m = 0;
                                    m <
                                    res.data.individualSubsidyLibraryList[i]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    array.forEach((item) => {
                                        if (
                                            item.name ==
                                            res.data
                                                .individualSubsidyLibraryList[i]
                                                .name
                                        ) {
                                            item.indicatorThirdLibraryList.push(
                                                {
                                                    name: res.data
                                                        .individualSubsidyLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .name,
                                                    ratingDimension:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].ratingDimension,
                                                    score: res.data
                                                        .individualSubsidyLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].score,
                                                    poorScore:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].poorScore,
                                                    generalScore:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].generalScore,
                                                    goodScore:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].goodScore,
                                                    id: res.data
                                                        .individualSubsidyLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].thirdLibraryId,
                                                    intension:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .intension,
                                                    explain:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .explain,
                                                    suggestedScore:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .suggestedScore,
                                                    ratingDimension:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].ratingDimension + "",
                                                    characteristic:
                                                        res.data
                                                            .individualSubsidyLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .characteristic,
                                                    unit: res.data
                                                        .individualSubsidyLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .unit,
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        } else if (this.stepParams[j].title == "专项奖励指标") {
                            for (
                                let i = 0;
                                i < res.data.specialRewardsLibraryList.length;
                                i++
                            ) {
                                array.push({
                                    name: res.data.specialRewardsLibraryList[i]
                                        .name,
                                    id: res.data.specialRewardsLibraryList[i]
                                        .id,
                                    explain:
                                        res.data.specialRewardsLibraryList[i]
                                            .explain,
                                    intension:
                                        res.data.specialRewardsLibraryList[i]
                                            .intension,
                                    indicatorThirdLibraryList: [],
                                });
                                for (
                                    let m = 0;
                                    m <
                                    res.data.specialRewardsLibraryList[i]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    array.forEach((item) => {
                                        if (
                                            item.name ==
                                            res.data.specialRewardsLibraryList[
                                                i
                                            ].name
                                        ) {
                                            item.indicatorThirdLibraryList.push(
                                                {
                                                    name: res.data
                                                        .specialRewardsLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .name,
                                                    ratingDimension:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].ratingDimension,
                                                    score: res.data
                                                        .specialRewardsLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].score,
                                                    poorScore:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].poorScore,
                                                    generalScore:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].generalScore,
                                                    goodScore:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].goodScore,
                                                    id: res.data
                                                        .specialRewardsLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].thirdLibraryId,
                                                    intension:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .intension,
                                                    explain:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .explain,
                                                    suggestedScore:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .suggestedScore,
                                                    characteristic:
                                                        res.data
                                                            .specialRewardsLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .characteristic,
                                                    unit: res.data
                                                        .specialRewardsLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .unit,
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        } else if (
                            this.stepParams[j].title ==
                            "工作量指标-医疗服务项目"
                        ) {
                            for (
                                let i = 0;
                                i < res.data.workloadLibraryList.length;
                                i++
                            ) {
                                if (
                                    res.data.workloadLibraryList[i].name ==
                                    "医疗服务项目"
                                ) {
                                    array.push({
                                        name: res.data.workloadLibraryList[i]
                                            .name,
                                        id: res.data.workloadLibraryList[i].id,
                                        explain:
                                            res.data.workloadLibraryList[i]
                                                .explain,
                                        intension:
                                            res.data.workloadLibraryList[i]
                                                .intension,
                                        indicatorThirdLibraryList: [],
                                    });
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.workloadLibraryList[i]
                                            .performanceAllocationSchemeDetailList
                                            .length;
                                        m++
                                    ) {
                                        array.forEach((item) => {
                                            if (
                                                item.name ==
                                                res.data.workloadLibraryList[i]
                                                    .name
                                            ) {
                                                item.indicatorThirdLibraryList.push(
                                                    {
                                                        name: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .name,
                                                        ratingDimension:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ].ratingDimension,
                                                        score: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].score,
                                                        id: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].thirdLibraryId,
                                                        intension:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .intension,
                                                        explain:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .explain,
                                                        suggestedScore:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .suggestedScore,
                                                        characteristic:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .characteristic,
                                                        unit: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .unit,
                                                    }
                                                );
                                            }
                                        });
                                    }
                                }
                            }
                        } else if (
                            this.stepParams[j].title == "工作量指标-病历病种"
                        ) {
                            for (
                                let i = 0;
                                i < res.data.workloadLibraryList.length;
                                i++
                            ) {
                                if (
                                    res.data.workloadLibraryList[i].name ==
                                    "病历病种"
                                ) {
                                    array.push({
                                        name: res.data.workloadLibraryList[i]
                                            .name,
                                        id: res.data.workloadLibraryList[i].id,
                                        explain:
                                            res.data.workloadLibraryList[i]
                                                .explain,
                                        intension:
                                            res.data.workloadLibraryList[i]
                                                .intension,
                                        indicatorThirdLibraryList: [],
                                    });
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.workloadLibraryList[i]
                                            .performanceAllocationSchemeDetailList
                                            .length;
                                        m++
                                    ) {
                                        array.forEach((item) => {
                                            if (
                                                item.name ==
                                                res.data.workloadLibraryList[i]
                                                    .name
                                            ) {
                                                item.indicatorThirdLibraryList.push(
                                                    {
                                                        name: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .name,
                                                        ratingDimension:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ].ratingDimension,
                                                        score: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].score,
                                                        id: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].thirdLibraryId,
                                                        intension:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .intension,
                                                        explain:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .explain,
                                                        suggestedScore:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .suggestedScore,
                                                        characteristic:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .characteristic,
                                                        unit: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .unit,
                                                    }
                                                );
                                            }
                                        });
                                    }
                                }
                            }
                        } else if (
                            this.stepParams[j].title == "工作量指标-业务量"
                        ) {
                            for (
                                let i = 0;
                                i < res.data.workloadLibraryList.length;
                                i++
                            ) {
                                if (
                                    res.data.workloadLibraryList[i].name ==
                                    "业务量"
                                ) {
                                    array.push({
                                        name: res.data.workloadLibraryList[i]
                                            .name,
                                        id: res.data.workloadLibraryList[i].id,
                                        explain:
                                            res.data.workloadLibraryList[i]
                                                .explain,
                                        intension:
                                            res.data.workloadLibraryList[i]
                                                .intension,
                                        indicatorThirdLibraryList: [],
                                    });
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.workloadLibraryList[i]
                                            .performanceAllocationSchemeDetailList
                                            .length;
                                        m++
                                    ) {
                                        array.forEach((item) => {
                                            if (
                                                item.name ==
                                                res.data.workloadLibraryList[i]
                                                    .name
                                            ) {
                                                item.indicatorThirdLibraryList.push(
                                                    {
                                                        name: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .name,
                                                        ratingDimension:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ].ratingDimension,
                                                        score: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].score,
                                                        id: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].thirdLibraryId,
                                                        intension:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .intension,
                                                        explain:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .explain,
                                                        suggestedScore:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .suggestedScore,
                                                        characteristic:
                                                            res.data
                                                                .workloadLibraryList[
                                                                i
                                                            ]
                                                                .performanceAllocationSchemeDetailList[
                                                                m
                                                            ]
                                                                .performanceIndicatorThirdLibrary
                                                                .characteristic,
                                                        unit: res.data
                                                            .workloadLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .unit,
                                                    }
                                                );
                                            }
                                        });
                                    }
                                }
                            }
                        } else if (this.stepParams[j].title == "基本指标") {
                            for (
                                let i = 0;
                                i < res.data.basicLibraryList.length;
                                i++
                            ) {
                                array.push({
                                    targetName: "基本指标",
                                    name: res.data.basicLibraryList[i].name,
                                    id: res.data.basicLibraryList[i].id,
                                    explain:
                                        res.data.basicLibraryList[i].explain,
                                    intension:
                                        res.data.basicLibraryList[i].intension,
                                    indicatorThirdLibraryList: [],
                                });
                                for (
                                    let m = 0;
                                    m <
                                    res.data.basicLibraryList[i]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    array.forEach((item) => {
                                        if (
                                            item.name ==
                                            res.data.basicLibraryList[i].name
                                        ) {
                                            item.indicatorThirdLibraryList.push(
                                                {
                                                    targetName: "基本指标",
                                                    name: res.data
                                                        .basicLibraryList[i]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .name,
                                                    ratingDimension:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].ratingDimension,
                                                    score: res.data
                                                        .basicLibraryList[i]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].score,
                                                    poorScore:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].poorScore,
                                                    generalScore:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].generalScore,
                                                    goodScore:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].goodScore,
                                                    id: res.data
                                                        .basicLibraryList[i]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].thirdLibraryId,
                                                    intension:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .intension,
                                                    explain:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .explain,
                                                    suggestedScore:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .suggestedScore,
                                                    characteristic:
                                                        res.data
                                                            .basicLibraryList[i]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .characteristic,
                                                    unit: res.data
                                                        .basicLibraryList[i]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .unit,
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        } else if (this.stepParams[j].title == "附加职责") {
                            for (
                                let i = 0;
                                i <
                                res.data.addResponsibilitiesLibraryList.length;
                                i++
                            ) {
                                array.push({
                                    name: res.data
                                        .addResponsibilitiesLibraryList[i].name,
                                    id: res.data.addResponsibilitiesLibraryList[
                                        i
                                    ].id,
                                    explain:
                                        res.data.addResponsibilitiesLibraryList[
                                            i
                                        ].explain,
                                    intension:
                                        res.data.addResponsibilitiesLibraryList[
                                            i
                                        ].intension,
                                    indicatorThirdLibraryList: [],
                                });
                                for (
                                    let m = 0;
                                    m <
                                    res.data.addResponsibilitiesLibraryList[i]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    array.forEach((item) => {
                                        if (
                                            item.name ==
                                            res.data
                                                .addResponsibilitiesLibraryList[
                                                i
                                            ].name
                                        ) {
                                            item.indicatorThirdLibraryList.push(
                                                {
                                                    name: res.data
                                                        .addResponsibilitiesLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .name,
                                                    ratingDimension:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].ratingDimension,
                                                    score: res.data
                                                        .addResponsibilitiesLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].score,
                                                    poorScore:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].poorScore,
                                                    generalScore:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].generalScore,
                                                    goodScore:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ].goodScore,
                                                    id: res.data
                                                        .addResponsibilitiesLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ].thirdLibraryId,
                                                    intension:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .intension,
                                                    explain:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .explain,
                                                    suggestedScore:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .suggestedScore,
                                                    characteristic:
                                                        res.data
                                                            .addResponsibilitiesLibraryList[
                                                            i
                                                        ]
                                                            .performanceAllocationSchemeDetailList[
                                                            m
                                                        ]
                                                            .performanceIndicatorThirdLibrary
                                                            .characteristic,
                                                    unit: res.data
                                                        .addResponsibilitiesLibraryList[
                                                        i
                                                    ]
                                                        .performanceAllocationSchemeDetailList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .unit,
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        }
                    }
                }

                this.perforModel.basData = array;
                // console.log(array);
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        characteristic(val) {
            // <!-- 指标特性（0加分 1减分 2固定分值  3评分） -->
            if (val == "0") {
                return "加分";
            } else if (val == "1") {
                return "减肥";
            } else if (val == "2") {
                return "固定金额";
            } else if (val == "3") {
                return "评分";
            }
        },
        unit(val) {
            // <!-- 单位（0次  1人/次  2半天/次） -->
            if (val == "0") {
                return "次";
            } else if (val == "1") {
                return "人/次";
            } else if (val == "2") {
                return "半天/次";
            }
        },
        // 分页显示病区列表数据
        async rewardList() {
            this.AdddialogVisible = false;
            this.diaBtn = "新增";
            this.diaTit = "新增绩效分配方案";
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                deleteFlag: "0", //数据状态（0正常 1已删除）
                name: this.searName, //方案名称
                departmentId: this.searcdepartmentId, //科室id
            };
            let { data: res } = await this.$axios.scheList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item {
    display: flex;
}
/deep/ .el-form-item__label {
    width: 100px;
}
/deep/ .el-textarea {
    width: 300px;
}
.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.coeClass {
    width: 100%;
    /deep/ .el-form-item__content {
        width: 90%;
        margin: 0 auto;
    }
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 10px;
}

.hosrow div:first-child {
    margin-left: 10px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-input .el-input__inner {
    // width: 300px;
    width: 100%;
}

/deep/ .newInp .el-input .el-input__inner {
    width: 200px !important;
}

/deep/ .newInp .el-textarea,
/deep/ .newInp .el-textarea__inner {
    width: 200px !important;
}

/deep/ .sorClas .el-input__inner {
    width: 200px;
}

/deep/ .bigClass.el-form-item {
    margin-bottom: 0px;
}

/deep/ .bigClass .el-form-item__error {
    position: relative !important;
    top: 0;
    float: left;
}

/deep/ .hosrow .el-input .el-input__inner {
    width: 200px;
}

/deep/ .depstep.el-steps {
    margin-bottom: 30px;
}

.depForm {
    width: 550px;
    margin: 0 auto;
}

.depDivBig {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    margin: 0 auto;
    .depDivSmal {
        width: 100%;
        margin-right: 30px;
        margin-bottom: 20px;
    }
    .deptooDiv {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        span {
            width: 100%;
            text-align: center;
        }
    }
}

.otherClas {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
}

/deep/ .depDivSmal .el-form-item__content,
/deep/ .wriClas .el-form-item .el-form-item__content {
    margin-left: 0px !important;
}

/deep/ .wriClas .docClas.el-form-item .el-form-item__content {
    width: 100%;
}

.upWrit {
    display: flex;
}

/deep/ .el-step__title {
    font-size: 15px !important;
}

.btnCen {
    display: flex;
    justify-content: center;
    .el-button {
        width: 150px;
        height: 40px;
    }
    div {
        margin-left: 10px;
    }
}

.tabClas {
    /deep/ .el-form-item__content {
        width: 100%;
    }
}

.topName {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

/deep/ .raioClass .el-radio__label {
    display: flex;
    align-items: center;
}

/deep/ .raioClass .el-input__inner {
    margin-left: 20px;
}

/deep/ .raioClass .el-radio {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.woScCalas {
    margin-bottom: 30px;
    /deep/ .el-form-item__label-wrap {
        margin-left: 0px !important;
    }
    /deep/ .el-form-item__label {
        width: 51px !important;
    }
}

.heiClas {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
}

.remaClas {
    width: 30%;
    /deep/ .el-form-item__content {
        width: 100%;
    }
    /deep/ .el-form-item__content .el-textarea {
        width: 80% !important;
    }

    /deep/ .el-textarea__inner {
        height: 40px !important;
        padding: 5px 25px !important;
    }

    /deep/ .el-textarea .el-input__count {
        background: transparent !important;
    }
}
</style>
